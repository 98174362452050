.App {
  text-align: center;
}

.Text {
  font-family: 'Noto Sans Mono', monospace;
}
.Content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

.fade-in-later {
  animation: fadeIn 6s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
